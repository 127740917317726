.wrapper {
  width: 100%;
  max-width: 525px;
  margin: 0 auto;
  display: grid;
  // gap: 5rem;
  align-items: center;
  justify-items: flex-start;
  grid-template-columns: auto;
  padding-top: 7.5rem;
  position: relative;
  z-index: 1;
  counter-increment: section;

  button {
    border-radius: 100px;
  }
  .submit {
    display: flex;
    align-items: center;
  }
}

.stepFirst {
  margin-bottom: 10rem;
}

.stepSecond {
  margin-top: unset;
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.stepSecondBtnPd {
  padding-bottom: 5rem !important;
}

.step {
  width: 100px;
  display: grid;
  grid-auto-flow: column;
  gap: 6px;
  font-size: 1.8rem;
  strong {
    color: rgb(47, 47, 47);
  }
  p {
    color: rgba(129, 129, 129, 0.787);
    font-weight: 600;
  }
}

.input_container {
  display: grid;
  gap: 4rem;
  width: 100%;
  margin-top: 4.8rem;
  margin-bottom: 5rem;
}

.input_box {
  display: grid;
}

.input,
.input_title {
  color: #565656;
}

.input_title {
  font-size: 1.6rem;
  font-weight: 400;
  margin-bottom: 1rem;
}

.input {
  width: 100%;
  border-radius: 100px;
  height: 50px;
  border: 1px solid #aeaeae;
  padding: 1.1rem 4rem;
  color: #404040;
  font-size: 1.8rem;
  font-weight: 400;
  position: relative;
}

.icon {
  position: absolute;
  bottom: 0;
  top: 18px;
  right: 30px;
  color: #aeaeae;
  cursor: pointer;
}

.submitError {
  border: 1px solid #ff8282;
}

// Disable
.disableEvents {
  pointer-events: none;
}

.relativeWrapper {
  width: 100%;
  position: relative;
}

.icon_box {
  display: grid;
  grid-auto-flow: column;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  grid-template-columns: 0fr 0fr 0fr;
  margin-right: 4rem;
  justify-content: flex-end;
  align-items: center;
  gap: 1.5rem;

  i {
    color: #aeaeae;
    cursor: pointer;
  }
  p {
    color: #565656;
    font-size: 1.8rem;
    font-weight: 400;
  }
}

// Boolean
.boolean_layout {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 2.5rem;
}

@media (max-width: 50em) {
  .wrapper {
    // width: 100vw;
    // display: block;
    align-items: center;
    justify-items: unset;
    max-width: unset !important;
    padding: 6rem 2.5rem 3.2rem 6.3rem;
    .submit {
      display: unset;
    }
  }
  .input {
    width: 100%;
  }
  .input_title {
    font-size: 1.4rem;
  }

  .input_box {
    grid-template-columns: 1fr;
  }

  // .input_container {
  //   display: block;
  //   gap: 4rem;
  //   width: 100%;
  //   margin-top: 4.8rem;
  //   margin-bottom: 5rem;
  // }

  .stepFirst {
    margin-bottom: unset;
  }
  .stepSecondBtnPd {
    padding-bottom: unset !important;
  }
}

@media (max-width: 50em) {
  .boolean_layout {
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    gap: 1.6rem;
    span {
      flex: 1 0;
    }
  }
}
