@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

:root {
  --black: #34314c;
  --lightBlue: #e4f6fc;
  --blue: #2196f3;
  --red: #ff6969;
  --main: #565656;
  --green: #00b746;
  --gray: #828282;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
}

::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}

html {
  font-size: 62.5%;
  min-height: 101vh;
}

body {
  font-size: 1.6rem;
}

button {
  cursor: pointer;
  border: none;
  outline: none;
  background: none;
}

input {
  border: none;
  outline: none;
}

a {
  text-decoration: none;
}

p {
  margin-bottom: 0;
}
// Hide input number arrows
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

[type='button'],
[type='reset'],
[type='submit'],
button {
  -webkit-appearance: none;
}
