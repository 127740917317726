.wrapper {
  display: grid;
  grid-template-columns: 1fr;
  gap: 22rem;
  justify-content: center;
  align-items: center;
  background-size: cover;
  height: 550px;

  * {
    white-space: nowrap;
  }
}

.hero_container {
  display: grid;
  padding: 2rem;
  gap: 2rem;
  grid-template-columns: repeat(2, auto);
  align-items: center;
  justify-content: center;
  justify-items: center;

  // padding: 0 5rem;
}

.hero {
  display: grid;
  justify-items: flex-start;
  gap: 3rem;
  .title {
    font-size: 4.5rem;
    font-weight: 700;
    line-height: 54px;
    color: #34314c;
  }
  .description {
    font-size: 1.8rem;
    color: #565656;
  }
}
.logo {
  width: 100%;
  max-width: 400px;
}

@media (max-width: 60em) {
  .wrapper {
    height: unset;
  }
  // Ignore line break
  br {
    display: none;
  }
  .hero_container {
    gap: 1rem;
    padding: 0rem 3rem;
    justify-content: center;
  }
  .hero {
    margin-top: 6rem;
    gap: 1.6rem;
    .title {
      font-size: 3.4rem;
      white-space: pre-wrap;
      max-width: 317px;
      line-height: 42px;
    }
    .description {
      font-size: 1.6rem;
      white-space: pre-wrap;

      width: 100%;
      line-height: 27px;
      margin-top: -0.8rem;
    }
  }
  .logo {
    grid-row: 2/3;
    max-width: 180px;
    margin-top: 2.4rem;
  }
}
