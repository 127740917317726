.svg-animation-web {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  transition: 2s;
  width: 970px;
  .path {
    stroke-dasharray: 1000;
    stroke-dashoffset: 1000;
    transition: 2s;
  }
  #rope1 {
    transition: 2s;
  }

  .yellow-arrows {
    position: absolute;
    left: -7px;
    height: 100%;
    animation-name: rope1;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in-out;
    animation-duration: 2s;
    #yellow1 {
      transform: translateY(-160px);
    }
    #yellow2 {
      transform: translateY(-140px);
    }
  }

  .green-arrows {
    position: absolute;
    right: -7px;
    height: 100%;
    top: 40%;
    z-index: 1;
    animation-name: rope2;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in-out;
    animation-duration: 2s;
    #green1 {
      left: 10px;
      bottom: 0;
      transform: translateY(200px);
    }
    #green2 {
      transform: translateY(180px);
    }
  }

  .leftCorner1 {
    position: absolute;
    z-index: 999;
    bottom: -58px;
    left: 0.5px;
  }
  .horizantal1 {
    position: absolute;
    bottom: -58px;
    left: 56px;
  }
  .rightCorner1 {
    position: absolute;
    bottom: -127px;
    right: 0;
  }

  .rightRope {
    position: absolute;
    right: -0.3px;
    bottom: -316px;
    z-index: 0;
    &2 {
      bottom: -608px;
    }
  }
  .rightCornerBottom {
    position: absolute;
    right: 0;
    bottom: -122.5%;
  }
  .bottomRope2 {
    position: absolute;
    bottom: -122.5%;
    right: 58px;
  }
  .rightCorner2 {
    position: absolute;
    bottom: -129.8%;
    right: 420px;
    width: 100px;
  }
  #blueArrow {
    transform: translateY(27px) translate(-7px);
  }
}

@keyframes rope1 {
  0% {
    top: 0;
  }
  100% {
    top: 40%;
  }
}

@keyframes rope2 {
  0% {
    top: 40%;
  }
  100% {
    top: 68%;
  }
}

// Mobile Version
.svg-animation-mob {
  display: none;
  position: absolute;
  left: 25px;

  bottom: 0;
  margin: 0 auto;
  width: 20px;
  #arrow1,
  #arrow2 {
    position: absolute;
  }
  #arrow1 {
    transform: translate(-7px, 0%);
    animation: arrowMove 2s ease-in-out forwards;
  }

  // Disabled
  #arrow2 {
    display: none;
    transform: translate(-7px, 53%);
  }
}

// Animation for Mobile
@keyframes arrowMove {
  0% {
    transform: translate(-7px, 0%);
  }
  100% {
    transform: translate(-7px, 50%);
  }
}

@media (max-width: 50em) {
  .svg-animation-web {
    display: none;
  }
  .svg-animation-mob {
    display: block;
  }
}
