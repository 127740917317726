$black: #34314c;
$main: #565656;
$white: #fff;
$blue: #2196f3;
// Tabmenu and Cost Area

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.bar1,
.bar2 {
  width: 344px;
}

.tabMenu {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-items: center;
  justify-content: center;
  gap: 16px;
  margin-top: 10rem;
  max-width: 325px;

  &_content {
    text-align: center;
    white-space: pre-wrap;
  }
  &_title {
    margin-top: 5.7rem;
    font-size: 3.4rem;
    color: $black;
    font-weight: 600;
  }
  &_progressContainer {
    align-items: center;
    justify-content: center;
    margin-top: 6.4rem;
    display: grid;
    gap: 2.8rem;
    // ProgresBar Wrapper
    .progressBar {
      display: grid;
      grid-template-columns: 1fr auto;
      place-items: center;
      position: relative;
      gap: 2.1rem;
      &_cost {
        position: absolute;
        font-weight: 600;
        font-size: 2.2rem;
        left: 0;
        margin-left: 20rem;
      }
    }
  }
}

.menuItem {
  cursor: pointer;
  padding-bottom: 1.6rem;
  border-bottom: 2px solid #2195f300;
  font-weight: 700;
  font-size: 1.8rem;
  color: $black;
}

// Breakdown Area

.breakDown {
  margin-top: 9.6rem;
  width: 100%;
  &_title {
    text-align: center;
    color: $black;
    font-weight: 600;
    font-size: 3.4rem;
  }
  &_stats {
    display: grid;
    margin: 0 auto;
    grid-template-columns: 0fr 0fr;
    justify-content: space-between;
    width: 100%;
    max-width: 835px;
    margin-top: 6.4rem;
  }
}

.svg {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  &_container {
    position: absolute;
  }
  &_currency,
  &_amount {
    display: inline;
    font-size: 4rem;
    color: $blue;
    font-weight: 700;
  }
  &_subTitle {
    text-align: center;
    color: $black;
    font-weight: 400;
    font-size: 1.6rem;
  }
}

.labels {
  display: grid;
  grid-template-columns: repeat(2, auto);
  justify-content: center;
  gap: 4rem 7rem;
  align-items: center;

  &_container {
    display: grid;
    grid-template-columns: 1fr 190px;
    gap: 0.4rem 2.4rem;
  }
  &_value {
    color: $main;
    font-weight: 600;
    font-size: 2.4rem;
  }
  &_title {
    display: flex;
    align-items: center;
    grid-column: 2/3;
    font-weight: 400;
    color: $main;
    font-size: 1.6rem;
  }
}

// Disclaimer
.disclaimer {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 0fr 0fr;
  align-items: center;
  justify-content: center;
  gap: 1.2rem;
  margin-top: 3.8rem;
  position: relative;
  cursor: pointer;
  &_icon {
  }
  &_text {
    font-weight: 400;
    font-size: 1.6rem;
  }
}

// Cta
.cta {
  display: flex;
  flex-direction: column;
  place-items: center;
  margin-top: 4.3rem;
  margin-bottom: 4rem;
  font-size: 3.4rem;
  font-weight: 600;
  &_icon {
    margin-top: 2.7rem;
  }
  &_text {
    color: $black;
  }
}

// Toggle Classes

.Active {
  border-bottom: 2px solid $blue;
}

.Passive {
  opacity: 0.6 !important;
}

// Colors
.textWhite {
  color: $white;
}

.textDark {
  color: $main;
}

@media (max-width: 60em) {
  .wrapper {
    padding: 0 2.5rem;
  }

  .tabMenu {
    margin-top: 4rem;
    // Label Title
    &_content {
      font-size: 1.2rem;
    }

    &_title {
      max-width: 325px;
      margin: 0 auto;
      margin-top: 4rem;
      font-size: 2rem;
      line-height: 24px;
      align-self: center;
    }
    &_progressContainer {
      width: 100%;
      margin-top: 4.2rem;
      gap: 1.6rem;
      justify-content: space-between;
      .progressBar {
        width: 100%;
        grid-template-columns: 1fr 200px;

        &_cost {
          font-size: 1.8rem;
          margin-left: 15rem;
        }
        .bar1 {
          width: 100%;
        }
        .bar2 {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .breakDown {
    width: unset;
    align-items: center;
    justify-content: center;
    margin-top: 6.4rem;
    &_title {
      font-size: 2rem;
    }
    &_stats {
      width: unset;
      gap: 3rem;
      justify-content: center;
      grid-template-columns: 1fr 1fr;
      img {
        max-width: 136px;
      }
    }
    .svg {
      align-self: start;

      &_amount,
      &_currency {
        font-size: 2.4rem;
      }
      &_subTitle {
        font-size: 1.2rem;
      }
    }
  }

  .labels {
    grid-template-columns: 1fr;
    gap: 1.8rem;

    &_value {
      font-size: 1.6rem;
    }
    &_title {
      font-size: 1.2rem;
    }
    &_dot {
      width: 16px !important;
      height: 16px !important;
    }
    &_container {
      grid-template-columns: 0fr 1fr;
      justify-content: flex-start;
      align-items: center;
      gap: 0.4rem 0.8rem;
    }
  }

  .disclaimer {
    margin-top: -13.8rem;
    &_text {
      font-size: 1.4rem;
    }
  }

  .cta {
    text-align: center;
    font-size: 2rem;
    &_icon {
      margin-top: 0.8rem;
    }
  }
}
